<template>
  <div class="ClassList">
      <Head />
      <el-table
      :data="tableData2"
      style="width: 100%"
      :row-class-name="tableRowClassName"
      @row-click="rowClik"
      >
     
      <el-table-column
        prop="name"
        label="项目名称"
        >
      </el-table-column>
      <!-- <el-table-column
        prop="description"
        label="课程描述">
      </el-table-column> -->
      <el-table-column
        prop="educator_project_status"
        label="项目状态">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { netService } from '../service.js'
import Cookie from 'js-cookie'
import Head from './Head'

export default {
  name: 'ClassList',
  mounted() {
    const studentid = Cookie.get('studentid')

    netService({
      functionName: 'getEducatorProjects',
      educator_id: studentid
    }).then(data => {
      // console.log(data)
      // this.project_template_id = data.project_template_id
      data.map(i => {
        this.tableData2.push({
          name: i.project_name,
          // sessionNum: i.class_info.session_ids.length,
          educator_project_id: i.educator_project_id,
          educator_project_status: i.educator_project_status,
          project_template_id: i.project_template_id
          // img_url: i.class_info.class_info.class_avatar_url,
        })
      })})
  },
  components: {
    Head,
  },
  methods: {
      tableRowClassName({ rowIndex }) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },
      rowClik(row) {
        console.log(row)
        this.$router.push({ path: 'TeamCodeList', query: { educator_project_id: row.educator_project_id, project_template_id: row.project_template_id, from: 'classList' }})
      }
    },
    data() {
      return {
        tableData2: []
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
.el-table{
      width: 80%!important;
    margin: 0 auto;
}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  .ClassList{
    width: 100%;
    /* margin: 0 auto; */
  }
  .avatar_url{
    width: 50px;
    height: 50px;
    transform: translate(0, 10%);
    /* border-radius: 50%;  */
  }
</style>
<style>
.el-table__row{
      cursor: pointer;
}
</style>
