
<template>

  <div class="ClassList">
      <Head />
      <div class="export">
      <el-button type="primary" @click="initExport" style="margin-right:10px;margin-top:10px" v-if="this.user_type == 'admin'">导出队伍列表</el-button></div>
      <div class="table" v-for="(item,index) in tableData2" :key="index">
        <div class="table1">
          <div style="display:flex">
            <!-- <div>{{item.number}}<span style="margin-left:12px;font-size:14px">{{item.name}}</span></div> -->
            <div>{{item.number}}</div>
          </div>
          <div style="display:flex">
            <div style="margin-left:20px">Team</div>
            <div>{{item.code}}</div>
          </div>
          
        
        </div>
        <div style="margin-top:10px;font-size:10px">
            <span v-for="(student,key) in item.students_arr" :key="key">
              {{student.china_name}}
            </span>
          </div>
        <div class="table2" >
          <div style="font-size:18px;font-weight:bold">{{item.project_title}}</div>
        </div>
        <div class="table3">
          <div>完成进度</div>
          <div>{{item.completion_rate}}</div>
          <div>检查进度</div>
          <div>{{item.check_label}}</div>
          <div>字数长度</div>
          <div>{{item.total_length}}</div>
          <div>评语数量</div>
          <div class="comment" @click="goComment(index)">{{item.comment_count}}</div>
          <div>备注信息</div>
          <div style="width:150px">{{item.note}}</div>
          <div>
            <el-button type="primary" size="mini" @click="getPdf(index)">汇总</el-button>
            <el-button type="primary" size="mini" @click="getChecklist(index)">检查总表</el-button>
            <el-button type="primary" size="mini" @click="goHome(index)">查看</el-button>
            <el-button type="primary" size="mini" @click="goTeamResults(index)" :disabled="forbid_edit">评奖</el-button>
            <el-button type="primary" size="mini" @click="goEvaluation(index)" v-if="(from !== 'accountList'&&  from !== 'student')|| (from === 'accountList' && identity === 'admin') ">评分</el-button>
          </div>
        </div>
      </div>
      
      <el-dialog :visible.sync="show">
         
        <el-checkbox v-for="item in totalDatas" :key="item.title" v-model="item.selected">{{item.title}}</el-checkbox>
        <div style="margin-top:10px">
            <el-button @click="exportCsv" type="primary" size="medium">导出</el-button>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import { netService ,export_csv,importComments,judgeForbidStatus} from '../service.js'
import Head from './Head'
import Cookie from 'js-cookie'
import { showLoading, hideLoading } from '../util.js'

export default {
  name: 'TeamCodeList',
  components: {
    Head,
  },
  mounted() {
    showLoading()
    const studentid = Cookie.get('studentid')
    this.user_type = Cookie.get('type')
    this.project_product = this.$router.currentRoute.query.project_product || ''
    this.educator_project_id = this.$router.currentRoute.query.educator_project_id || ''
    this.project_template_id = this.$router.currentRoute.query.project_template_id
    this.template_id = this.$router.currentRoute.query.template_id
    this.account_id = this.$router.currentRoute.query.account_id || ''
    this.from = this.$router.currentRoute.query.from
    this.identity = this.$router.currentRoute.query.identity || ''
    this.project_id = this.$router.currentRoute.query.project_id || ''
    console.log(this.user_type)


      judgeForbidStatus(this.project_id,this.account_id,studentid).then(res=>{
        if(res.data){
          this.forbid_edit = res.data
        }
      })
    
    
    if (this.from == 'classList' || this.user_type == 'admin'){
    netService({
      functionName: 'getProjectTeamcodes',
      project_id: this.educator_project_id
    }).then(data => {
      console.log(111111,data)
          data.map(i => {
        this.tableData2.push({
          name: i.account_name,
          nameEN: i.account_nameEN,
          code: i.team_code,
          completion_rate: i.completion_rate,
          check_label: i.check_count +'/'+i.check_total,
          total_length:i.total_length,
          comment_count:i.comment_count,
          note:i.note,
          id: i.account_id,
          project_title: i.project_title? i.project_title : '',
          students_arr:i.studentarr.students_arr,
          leader:i.studentarr.leader?i.studentarr.leader:'',
          rate:i.check_count/i.check_total,
          Media_Entry_Type:i.Media_Entry_Type
        })
      })
      for(let i = 0; i < this.tableData2.length; i++){
          this.tableData2[i].number = i + 1
      }
      console.log(this.tableData2)
     
      hideLoading()
    })
    }
    else if(this.from == 'accountList'){
      netService({
        functionName: 'getProjectTeamcodesByAccount',
        project_id: this.educator_project_id,
        account_id: this.account_id
      }).then(data => {
        console.log(data)
        
        data.map(i => {
          this.tableData2.push({
            name: i.account_name,
            nameEN: i.account_nameEN,
            code: i.team_code,
            completion_rate: i.completion_rate,
            total_length:i.total_length,
            check_label: i.check_count +'/'+i.check_total,
            comment_count:i.comment_count,
            students_arr:i.studentarr.students_arr,
            id: i.account_id
          })
      })
      for(let i = 0; i < this.tableData2.length; i++){
          this.tableData2[i].number = i + 1
      }
      hideLoading()
      })
    }
    else if(this.from == 'student'){
      const studentid = Cookie.get('studentid')
      netService({
        functionName: 'getProjectTeamcodesByStudent',
        project_id: this.project_id,
        student_id: studentid
      }).then(data => {
      
        console.log(data)
        
        data.map(i => {
          this.tableData2.push({
            name: i.account_name,
            code: i.team_code,
            completion_rate: i.completion_rate,
            total_length:i.total_length,
            check_label: i.check_count +'/'+i.check_total,
            comment_count:i.comment_count,
            id: i.account_id
          })
      })
      for(let i = 0; i < this.tableData2.length; i++){
          this.tableData2[i].number = i + 1
      }
      hideLoading()
      })
    }
  },
  methods: {
      goTeamResults(index){
        console.log(index)
         this.$router.push({
              path: 'TeamResults', query: {
                  project_id: this.educator_project_id,
                  account_id: Cookie.get('type') == 'student'?Cookie.get('studentid'): this.tableData2[index].id,
                  team_code: this.tableData2[index].code
              }})
      },
      
      getPdf(index){
        // console.log('info:'+JSON.stringify(info))
          // this.$router.push({
          //     path: 'toPrint', query: {
          //         project_id: this.educator_project_id,
          //         account_id :this.tableData2[index].id,
          //         team_code: this.tableData2[index].code
          //     }})
          console.log(this.educator_project_id)
          console.log(this.tableData2[index].id)
          console.log(this.tableData2[index].code)
          var project_product = this.project_product

        //添加测试
        if(true){
          let routeUrl = this.$router.resolve({
            path: "toPrint",
            query: {
                project_id: this.educator_project_id,
                account_id :this.tableData2[index].id,
                team_code: this.tableData2[index].code,
                project_product:project_product
            }
            });
            window.open(routeUrl.href, '_blank');
        }else {
        }
      },
      getChecklist(index){
        this.$router.push({
              path: 'checklist', query: {
                  project_id: this.educator_project_id,
                  account_id :this.tableData2[index].id,
                  team_code: this.tableData2[index].code
              }})
      },
      tableRowClassName({ rowIndex }) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },
      goHome(index){
        let routeData;
        let project_product = this.project_product
        if (this.from == 'student') {
          routeData = this.$router.resolve({ path: 'Home', query: {  project_product:project_product,template_id: this.project_template_id,project_id: this.educator_project_id,application_team_code:this.tableData2[index].code, accountid: this.tableData2[index].id }})
        } else {
          routeData= this.$router.resolve({ path: 'Home', query: { project_product:project_product, template_id: this.project_template_id,project_id: this.educator_project_id,application_team_code:this.tableData2[index].code, accountid: this.tableData2[index].id }})
        }
        window.open(routeData.href, '_blank');
      },
      goEvaluation(index){
          const studentid = Cookie.get('studentid')
          this.$router.push({
            path: 'Score',
            query: { 
              project_id: this.educator_project_id,
              account_id :this.tableData2[index].id,
              team_code: this.tableData2[index].code,
              educator_id: studentid,
              identity: this.identity,
              mode:'single'
            }
          })
      },
      goComment(index){
        this.$router.push({
          path: 'Comment',
          query: { 
            project_id: this.educator_project_id,
            account_id :this.tableData2[index].id,
            team_code: this.tableData2[index].code
          }
        })
      },
      getPro(){},
      exportCsv(title, data, fileName){
            var CSV = '';
            var row = "";
            var fileName = 'csv';

            let temp = []

            data=this.tableData2
            console.log(this.tableData2)

            title=this.totalDatas.filter(item=>item.selected)
            
            for (var i = 0; i < title.length; i++) {
            if(title[i].title){
                row += title[i].title + ',';
            }
            }
            row = row.slice(0, -1);
            CSV += row + '\r\n';
            
            for (var i = 0; i < data.length; i++) {
            var row = "";
            for (var j = 0; j < title.length; j++) {
            if(title[j].title){
                if(title[j].title!=="学生列表"){
                  if (title[j].title == '汇总链接'){
                    row += '"' +  'https://project.learningfirst.cn/#/toPrint?project_id='+this.educator_project_id + '&account_id=' +data[i]['id'] + '&team_code='+ data[i]['code'] + '&project_product='+ this.project_product + '"\t,';
                  }else{
                    row += '"' +  data[i][title[j].field] + '"\t,';
                  }
                }else{
                    for(var k = 0;k<data[i][title[j].field].length;k++){
                        row += '"' + data[i][title[j].field][k].china_name+' '+data[i][title[j].field][k].student_email+'"\t,';
                    }
                }
            }
            }
            row.slice(0, row.length - 1);
            CSV += row + '\r\n';
            }
            
            if (CSV == '') {
                alert("Invalid data");
                return;
            }
            
            var fileName = fileName;
            var uri = new Blob(['\ufeff' + CSV], {type:"text/csv"});
            
            if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
            window.navigator.msSaveOrOpenBlob(CSV, fileName + ".csv");
            } else { // for Non-IE（chrome、firefox etc.）
            var link = document.createElement("a");
            link.href = URL.createObjectURL(uri);
            
            link.style = "visibility:hidden";
            link.download = fileName + ".csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            }
        },
        compare(property,desc) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                if(desc==true){
                    // 升序排列
                    return value1 - value2;
                }else{
                    // 降序排列
                    return value2 - value1;
                }
            }
        },
        initExport(){
            this.show = true
        },
        importCsv(){
            let file = document.getElementById('file')
            let that = this
            let reader = new FileReader();
            reader.readAsText(file.files[0],'GB2312');
            reader.onload = function() {
                let data = that.csvToObject(this.result)
                console.log(data)
                console.log(that.tableData2)
                let totalDatas = [];
                for(let item of that.tableData2){
                    
                    let index = item.number
                    let fileItem = data.find(x=>Number(x.index)===index)
                    if(fileItem){
                        let cursor = {
                            account_id:item.id,
                            team_code:item.code,
                            content:fileItem.content.replace(/，/g,',')
                        }
                        totalDatas.push(cursor)
                    }
                }
                importComments(totalDatas)
            
                
            }
       
        },
        csvToObject(csvString){
            let csvarray = csvString.split("\r\n");
            let datas = [];
            let headers = ['index','content'];
            for(let i = 1;i<csvarray.length;i++){
                let data = {};
                let temp = csvarray[i].split(",");
                for(let j = 0;j<temp.length;j++){
                    if(temp[j]!==''){
                        data[headers[j]] = temp[j];
                    }
                }
                if(JSON.stringify(data) !== '{}'){
                    datas.push(data);
                }
            }
            return datas;
        }
        
    },
    
    data() {
      return {
        tableData2: [],
        class_id: '',
        user_type: '',
        selectDatas:[],
        show:false,
        totalDatas:[
            {title:'序号',field:'number',selected:false},
            {title:'学校名',field:'name',selected:false},
            {title:'学校英文名',field:'nameEN',selected:false},
            {title:'队伍编号',field:'code',selected:false},
            {title:'完成进度',field:'completion_rate',selected:false},
            {title:'检查进度',field:'check_label',selected:false},
            {title:'字数总长',field:'total_length',selected:false},
            {title:'评语数量',field:'comment_count',selected:false},
            {title:'备注',field:'note',selected:false},
            {title:'主题',field:'project_title',selected:false},
            {title:'领队',field:'leader',selected:false},
            {title:'学生列表',field:'students_arr',selected:false},
            {title:'作品类型',field:'Media_Entry_Type',selected:false},
            {title:'汇总链接',field:'huizong_url',selected:false}

        ],
        forbid_edit:false
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
.el-table{
      width: 80%!important;
    margin: 0 auto;
}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  .ClassList{
    width: 100%;
    /* margin: 0 auto; */
  }
</style>
<style>
.comment:hover{
    cursor:pointer;
    text-decoration: underline;
    color: blue;
}
.comment{
    text-decoration: underline;
    color: blue;
}
.table{
  font-size: 14px;
  color: #909399;
  width: 80%;
  margin: 20px auto;
  border-radius: 20px;
  border: 2px solid ;
  padding: 10px;

}
.table1{
  margin-top: 5px;
  font-family: "Microsoft YaHei","微软雅黑";
  display: flex;
 
  align-items: center;
  
}
.table2{
  display: flex;
  margin-top: 10px;
  align-items: center;
 
}
.table3{
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.export{
  display: flex;
  justify-content: flex-end;
}
</style>
