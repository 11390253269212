<template>
  <div class="ClassList">
      <Head />
      <div class="message">{{message}}</div>
      <div v-for="(data, dataindex) in dataList" :key="data._id.$id">
        <el-form class="result" v-for="(item, index) in data.test_results" :key="item.id" :model="item" label-width="60px">
        <el-form-item label="名字：">
          {{data.student_name.replace(' ','')}}
        </el-form-item>
        <el-form-item label="项目：">
          <span v-if="!item.isEdit">
            {{item.test_subject}}
          </span>
          <el-input v-model="item.test_subject" v-if="item.isEdit" @input="updateView($event)"></el-input>
        </el-form-item>

        <el-form-item label="代码：">
          <span v-if="!item.isEdit">
            {{item.test_subject_code}}
          </span>
          <el-input v-model="item.test_subject_code" v-if="item.isEdit" @input="updateView($event)"></el-input>
        </el-form-item>

        <el-form-item label="类别：">
          <span v-if="!item.isEdit">
            {{item.award_type}}
          </span>
          <!-- <el-input v-model="item.award_type" v-if="item.isEdit" @input="updateView($event)"></el-input> -->
          <el-select v-model="item.award_type" v-if="item.isEdit" placeholder="请选择奖项类别" @input="updateView($event)">
            <el-option label="individual" value="individual"></el-option>
            <el-option label="team" value="team"></el-option>
            
          </el-select>
        </el-form-item>

        <el-form-item label="分数：">
          <span v-if="!item.isEdit">
            {{item.test_score}}
          </span>
          <el-input v-model="item.test_score" v-if="item.isEdit" @input="updateView($event)"></el-input>
        </el-form-item>


          <span style="color:#666666;font-size:14px;font-family:'Microsoft YaHei';margin-left:5px">是否获奖：</span>
          <span v-if="!item.isEdit && (item.test_award==='N/A' || item.test_award=== '')" style="font-size:14px">
            未获奖
          </span>
          <span v-if="!item.isEdit && (item.test_award!=='N/A' && item.test_award!== '')" style="font-size:14px">
            已获奖
          </span>
      
        
        
        <el-form-item label="奖项：" style="margin-top:24px">
          
         
          <span v-if="!item.isEdit && (item.test_award!=='N/A')">
            {{item.test_award}}
          </span>
          <el-select v-model="item.test_award" v-if="item.isEdit" placeholder="请选择活动区域" @input="updateView($event)">
            <el-option label="N/A" value="N/A"></el-option>
            <el-option label="First Prize" value="First Prize"></el-option>
            <el-option label="Second Prize" value="Second Prize"></el-option>
            <el-option label="Third Prize" value="Third Prize"></el-option>
            <el-option label="Superior Award" value="Superior Award"></el-option>
            <el-option label="Excellent Award" value="Excellent Award"></el-option>
            <el-option label="Honorable Award" value="Honorable Award"></el-option>
            <el-option label="Honorable Mention Award" value="Honorable Mention Award"></el-option>
            <el-option label="Participation Award" value="Participation Award"></el-option>
            <el-option label="Impact Award" value="Impact Award"></el-option>
            <el-option label="National 1st Place" value="National 1st Place"></el-option>
            <el-option label="National 2nd Place" value="National 2nd Place"></el-option>
            <el-option label="National 3rd Place" value="National 3rd Place"></el-option>
            <el-option label="National 4th Place" value="National 4th Place"></el-option>
            <el-option label="National 5th Place" value="National 5th Place"></el-option>
            <el-option label="National 6th Place" value="National 6th Place"></el-option>
            <el-option label="National 7th Place" value="National 7th Place"></el-option>
            <el-option label="National 8th Place" value="National 8th Place"></el-option>
            <el-option label="National 9th Place" value="National 9th Place"></el-option>
            <el-option label="National 10th Place" value="National 10th Place"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证书：">
          <a v-if="!!item.uuid" :href='"http://view.certifi.tech/#/"+item.uuid' target="_blank">查看</a>
          <span v-if="!item.uuid" >还未公布</span>
        </el-form-item>
        <el-form-item label="证书模板：" v-if="_type=='admin' && educator_project_id!=='5efc6198a4640b8c3c8b4593'">
          <div >{{item.certifi_name}}</div>
          <el-select v-model="item.certifi_project_template_id" v-if="item.isEdit" placeholder="请选择证书" @input="updateView($event)">
            <el-option v-for="(option,key) in certifiTemplate" :key="key" :value="option._id.$id" :label="option.template_info.template_name"></el-option>
          </el-select>
        </el-form-item>
        
        <div class="button-group" v-if="_type=='admin'">
          <el-button type="primary" v-if="!item.isEdit" @click="edit(index,dataindex)">
            修改
          </el-button>
          <el-button type="primary" v-if="!item.isEdit" @click="del(index,dataindex)">
            删除

          </el-button>
          <el-button type="primary" v-if="item.isEdit" @click="confirmEdit(index,dataindex)">
            确认

          </el-button>
          <el-button type="primary" v-if="item.isEdit" @click="cancel(index,dataindex)">
            取消

          </el-button>
        </div>
      </el-form>
      </div>
      
      <!-- <div class="result" v-for="(item, index) in test_results" :key="item.id">
        
        
      </div> -->
      <div class="result" style="border: 0;" v-if="_type=='admin'">
        <el-button type="primary"  @click="add()">
          添加
        </el-button>
      </div>

  </div>
</template>

<script>
import { netService,updateTeamResults,getCertifiTemplatesByProject} from '../service.js'
import Head from './Head'
import Cookie from 'js-cookie'
import { formatDate } from '../util.js'
let flag = false
export default {
  name: 'TeamResults',
  components: {
    Head,
  },
  data() {
    return {
      test_results: [],
      oldInfo:{},
      flag:false,
      message: '',
      _type: '',
      dataList:[],
      certifiTemplate:[],
      qualified:'',
      certifi_name:''
    }
  },
  mounted() {
    
    this.educator_project_id = this.$router.currentRoute.query.project_id 
    this.account_id = this.$router.currentRoute.query.account_id
    this.team_code = this.$router.currentRoute.query.team_code
    
    this._type = Cookie.get('type')
    this.getResult()
    


    // for(let i = 0;i<=12;i++){
    //   this.updateResult(i)
    // }
  },
  methods: {
    updateView(e) {
      this.$forceUpdate()
    },
    getResult(team_code){
        const obj = this._type == 'student'? {
          'student_id': this.account_id
        }: {
          'account_id': this.account_id
        }

        console.log(this.educator_project_id)
        console.log(this.account_id)
        console.log(this.team_code)
        console.log(this._type)
        netService({
          functionName: 'getTeamResults',
          project_id: this.educator_project_id,
          
          team_code: this.team_code,
          type: this._type,
          ...obj
          }).then(async data => {
            if(data.status == 'YES'){
              let dataList=data.data
              console.log(1111)
              console.log(data.data)
               this.dataList = data.data.length ? data.data : []
              getCertifiTemplatesByProject({project_id:this.educator_project_id}).then(async res=>{
                let certifiTemplate=res.data_array
                this.certifiTemplate=certifiTemplate
                for(const data of this.dataList){
                  data.test_results =  data.test_results ? data.test_results : []
                  for(let i=0; i< data.test_results.length; i++){
                    const v = data.test_results[i]
                    
                    for(let j=0;j<certifiTemplate.length;j++){
                      if(v.certifi_project_template_id===certifiTemplate[j]._id.$id){
                        v.certifi_name=certifiTemplate[j].template_info.template_name
                      }
                    }

                    if('certificate_id' in v){
                        const certificate = await this.getCertificate(v.certificate_id)
                        if(certificate){
                            v.id = i
                            v.uuid = certificate.certificate_info.uuid
                            data.test_results[i] = v
                        }
                    } 
                  }
                  data.test_results = [...data.test_results]

                }
                this.dataList = [...this.dataList]
              // if(data){
              //   this.dataList = data
              //   this.test_results = data.test_results ? data.test_results : []
              // }
              
              this.message = data.msg
              // data = data.data
              // this.test_results = []
              
             
              // console.log(this.test_results)
              })
  
            }
            console.log(2222)
            console.log(this.dataList)
            

        })
      },
    getCertificate(id){
      return new Promise(function (resolve, reject){
        netService({
          functionName: 'getCertificate',
          id
          }).then(data => {
            console.log(data)
            resolve(data)
        })
      });
      
    },
    updateResult(index,dindex){
      const _ = this.dataList[dindex].test_results[index]
      
      netService({
          functionName: 'updateTeamResults',
          project_id: this.educator_project_id,
          account_id: this.account_id,
          team_code: this.team_code,
          certifi_project_template_id:_.certifi_project_template_id,
          certificate_id:_.certificate_id?_.certificate_id:'',
          test_subject: _.test_subject,
          test_subject_code: _.test_subject_code,
          test_score:_.test_score,
          test_award: _.test_award,
          award_type:_.award_type,
          index: index
          }).then(data => {
            console.log(data)
            
            
        })
    },
    edit(index,dindex){
      console.log(this.dataList[dindex].test_results[index])
      const _ = this.dataList[dindex].test_results[index]
      this.oldInfo = {
        'test_award': _.test_award,
        'award_type':_.award_type,
        'test_score': _.test_score,
        'test_subject': _.test_subject,
        'test_subject_code': _.test_subject_code,
        'certifi_project_template_id':_.certifi_project_template_id,
        'certifi_name':_.certifi_name

      }
      this.dataList[dindex].test_results[index]['isEdit'] = true
      this.dataList = [...this.dataList]
    },
    cancel(index,dindex){
      console.log(flag)
      if(flag == true){
         this.test_results.pop()
         this.test_results = [...this.test_results]
         return
      }
      this.dataList[dindex].test_results[index]= this.oldInfo 
      this.dataList[dindex].test_results[index]['isEdit'] = false
      this.dataList = [...this.dataList]
      flag == false
    },
    confirmEdit(index,dindex){
      this.dataList[dindex].test_results[index]['isEdit'] = false
      this.dataList = [...this.dataList]
      this.updateResult(index,dindex)
      flag == false

    },
    add(){
      flag = true
      console.log(flag)

      if(this.dataList.length == 0){
        this.dataList.push({
          '_id': {
            '$id': 'xxxxxxx'
          },
          'student_name': ''

        })
        this.dataList[this.dataList.length-1].test_results = [{
          'test_award': '',
          'test_score': '',
          'award_type':'',
          'test_subject': '',
          'test_subject_code': '',
          'certifi_project_template_id':''
        }]
        this.dataList = [...this.dataList]
      }else{
        this.dataList[this.dataList.length-1].test_results = [...this.dataList[this.dataList.length-1].test_results,{
          'test_award': '',
          'award_type':'',
          'test_score': '',
          'test_subject': '',
          'test_subject_code': '',
          'certifi_project_template_id':''
        }]
      }
      
      this.edit(this.dataList[this.dataList.length-1].test_results.length-1, this.dataList.length-1)
    },
    del(index,dindex){
      this.dataList[dindex].test_results.splice(index, 1)
      this.dataList = [...this.dataList]
      this.delNet(index,dindex)
    },
    delNet(index,dindex){
      netService({
          functionName: 'deleteTeamResults',
          project_id: this.educator_project_id,
          account_id: this.account_id,
          team_code: this.team_code,
          index
          }).then(data => {
          console.log(data)
      })
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  .message{
    text-align: center;
    margin: 10px ;
  }
.el-table{
      width: 80%!important;
    margin: 0 auto;
}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  .ClassList{
    width: 100%;
    /* margin: 0 auto; */
  }
  .result{
    padding: 10px;
    width: 50%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px auto;

  }
  .text{
    margin-top: 10px;
  }
  .button-group{
    margin-top: 20px;
  }
</style>
<style>
.el-table .cell{
  word-break: keep-all;
}
</style>

