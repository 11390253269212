
<template>
  <div class="ClassList">
      <Head />
      <div class="tip">*注意：本系统使用统一的用户账号，凡是使用Project Guide服务运行在本平台的项目均会显示在你的项目列表中进行统一管理</div>
      <el-table
      :data="tableData2"
      style="width: 100%;"
      :row-class-name="tableRowClassName"
      
      >
     
      <el-table-column
        prop="name"
        label="项目名称"
        >
      </el-table-column>
      <el-table-column
        width="400"
        label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="rowClik(scope.row)">项目列表</el-button>
            <el-button v-if="identity === 'admin'" type="primary" size="small" @click="getRatings(scope.row)">评分汇总</el-button>
            
          </template>
      </el-table-column>
      <!-- <el-table-column
        prop="educator_project_status"
        label="项目状态">
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import { netService } from '../service.js'
import Cookie from 'js-cookie'
import Head from './Head'
import { showLoading, hideLoading } from '../util.js'

export default {
  name: 'AccountList',
  mounted() {
    showLoading()
    const accountid = Cookie.get('accountid')
    const studentid = Cookie.get('studentid')
    const type = Cookie.get('type')
    if (type === 'admin') {
      this.identity = 'admin'
      netService({
        functionName: 'getAdminProjects'
      }).then(data => {
        console.log(data)
        data.map(i => {
          this.tableData2.push({
            name: i.project_name,
            project_product: i.project_product,
            project_template_id: i.template_id,
            project_id: i._id.$id
          })
          hideLoading()
        })
      })
    } else {
      netService({
        functionName: 'getAccountProjects',
        account_id: accountid
      }).then(data => {
        // console.log(data)
        // this.project_template_id = data.project_template_id
        data.map(i => {
          this.tableData2.push({
            name: i.project_name,
            project_product: i.project_product,
            // sessionNum: i.class_info.session_ids.length,
            //   educator_project_id: i.educator_project_id,
            //   educator_project_status: i.educator_project_status,
            project_template_id: i.template_id,
            project_id: i._id.$id
          })
          hideLoading()
        })
      })
    }
  },
  components: {
    Head,
  },
  methods: {
      tableRowClassName({ rowIndex }) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },
      rowClik(row) {
        // console.log('row:' + JSON.stringify(row))
        // console.log('row.project_product:',row.project_product)
        const studentid = Cookie.get('accountid')
        // Cookie.set('project_product',row.project_product)
        this.$router.push({ path: 'TeamCodeList', query: {project_product:row.project_product, educator_project_id: row.project_id, account_id: Cookie.get('accountid'), project_template_id: row.project_template_id,template_id: row.project_template_id,from: 'accountList', identity: this.identity }})
      },
      getRatings(row){
          const studentid = Cookie.get('studentid')
          this.$router.push({
            path: 'Score',
            query: { 
              project_id: row.project_id,
              educator_id: studentid,
              identity: this.identity,
              mode:'summary'
            }
          })
      },
    },
    data() {
      return {
        tableData2: [],
        identity: 'normal'
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
.el-table{
      width: 80%!important;
    margin: 0 auto;
}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  .ClassList{
    width: 100%;
    /* margin: 0 auto; */
  }
  .avatar_url{
    width: 50px;
    height: 50px;
    transform: translate(0, 10%);
    /* border-radius: 50%;  */
  }
  .tip{
    margin:20px auto;
    color:grey;
    font-size:10px;
    width:80%;
  }
</style>
<style>
.el-table__row{
      cursor: pointer;
}
</style>
