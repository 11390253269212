export const pdfTextColorMap = {
    'FBLA': '#070B55',
    'CHS': '#B7131A',
    'SI2': '#0074C0',
    'SI': '#50B7BD',
    'YOC':'#491787',
    'YIC':'#C53430',
    'MR':'#0E4890',
    'DEFAULT':'#0C3575'
}
export const normalColorMap = {
    'FBLA': '#070B55',
    'CHS': '#FFFFFF',
    'SI2': '#0074C0',
    'SI': '#FFFFFF',
    'YOC':'#FFFFFF',
    'YIC':'#FFFFFF',
    'MR':'#0E4890',
    'DEFAULT':'#0C3575'
}
export const studentColorMap = {
    'FBLA': '#333333',
    'CHS': '#333333',
    'SI2': '#333333',
    'SI': '#333333',
    'YOC':'#491787',
    'YIC':'#1D2B56',
    'MR':'#0E4890',
    'DEFAULT':'#333333'
}
export const schoolColorMap = {
    'FBLA': '#666666',
    'CHS': '#666666',
    'SI2': '#666666',
    'SI': '#666666',
    'YOC':'#666666',
    'YIC':'#666666',
    'MR':'#666666',
    'DEFAULT':'#666666'
}
export const blockColorMap = {
    'FBLA': '#B7131A',
    'CHS': '#B7131A',
    'SI2': '#0074C0',
    'SI': '#50B7BD',
    'YOC':'#491787',
    'YIC':'#C53430',
    'MR':'#0E4890',
    'DEFAULT':'#0C3575'
}
export const contentColorMap = {
    'FBLA': '#333333',
    'CHS': '#333333',
    'SI2': '#333333',
    'SI': '#333333',
    'YOC':'#333333',
    'YIC':'#333333',
    'MR':'#333333',
    'DEFAULT':'#333333'
}

export const blockContentLevel2BgMap = {
    'FBLA': '#FFFAFA',
    'CHS': '#FFFAFA',
    'SI2': '#F4FBFF',
    'SI': '#F4FBFC',
    'YOC':'#FCF7FF',
    'YIC':'#FFF7F7',
    'MR':'#EFF6FF',
    'DEFAULT':'#F5F9FF'
}
