<template>
  <div class="top-container">
    <el-image :src="require('../../assets/'+project_type+'/'+project_type+'_top_bg.png')"
              style="position:absolute;;width: 100%;height: 100%;object-fit: cover"
              lazy/>
    <div style="position: absolute;display: flex;flex-direction: column;align-items: center;justify-content: center">
      <p :style="{'color':getColor()}" class="project-name">{{ name }}</p>
      <p :style="{'color':getColor()}" class="project-title"
         style="margin-top: 10px;margin-left: 150px;margin-right:50px">{{ title }}</p>
    </div>
  </div>
</template>
<script>
import {normalColorMap} from '../../GlobalConfig'

export default {
  name: "PrintTop",
  props: {
    name: {
      type: String,
      default: () => {
      }
    },
    title: {
      type: String,
      default: () => {
      }
    },
    project_type: {
      type: String,
      default: () => {
      }
    },
  },
  methods: {
    getColor() {
      return normalColorMap[this.project_type]
    }
  }
}
</script>

<style scoped>

.top-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 360px;
}

</style>