import Vuex from 'vuex'
import Vue from 'vue'
import Cookie from 'js-cookie'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user_info: {}
  },
  mutations: {
    setUserValue(state, value) {
      
      state.user_info = value
      Cookie.set('user_name', (value.student_lastName || value.contact_lastName || value.educator_last_name) + (value.student_givenName || value.contact_givenName || value.educator_first_name)) // 根据类型来设置姓名
    }
  }
})

export default store