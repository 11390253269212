<template>
  <div class="student-container">
    <div class="student-content">
      <div v-for="item in students">
        <div class="item-container">
          <div style="position: absolute">
            <img :src="require('../../assets/'+project_type+'/'+project_type+'_student_bg.png')"
                 style="width: 320px;height: 180px;object-fit: fill" lazy/>
          </div>
          <div class="item-content">
            <p :style="{'color':getStudentNameColor()}" class="student_name">{{ item.student_name }}</p>
            <!-- <p :style="{'color':getSchoolColor()}" class="account_name account-name-1" style="margin-top: 10px"> -->
              <!-- {{ item.account_name }}</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {schoolColorMap, studentColorMap} from '../../GlobalConfig'

export default {
  name: "PrintStudent",
  props: {
    project_type: {
      type: String,
      default: () => {
      }
    },
    students: {
      type: Array,
      default: () => {
      }
    }
  },
  methods: {
    getStudentNameColor() {
      return studentColorMap[this.project_type]
    },
    getSchoolColor() {
      return schoolColorMap[this.project_type]
    }
  }
}
</script>

<style scoped lang="less">
.student-container {
  box-sizing: border-box;
  padding: 50px 30px 0px 30px;
  width: 100%;
}

.student-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  gap: 20px;
}

.item-container {
  box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.0600);
  width: 100%;
  height: 180px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.item-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.account-name-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

</style>