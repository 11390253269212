<template>
  <div class="container">
    <div v-for="checklist  in mutichecklist" :key="checklist._id.$id" class="check-box">
      <h3 v-if="checklist.check_contents.length">{{checklist.block_info.block_name}}:</h3>
      <div v-for="(item, index) in checklist.check_contents" :key="item._id.$id">
        <el-checkbox  :label="index" :key="item.check_content" v-model="item.check_status" ><div class="check-box-item"><span style="background:#DCDFE6;padding:1px 8px;margin-right:4px;border-radius:4px;color:#606266">{{item.check_type}}</span>{{item.check_content}}</div></el-checkbox>
      </div>
    </div>
    
  
  </div>

</template>

<script>
import Cookie from 'js-cookie'
import {  
    netService,
} from '../service.js'
export default {
  name: 'Checklist',
  props: {
    
  },
  computed:{
    name(){
      return Cookie.get('user_name')
    }
  },
  data() {
    return{
      mutichecklist: []
    }
  },
  mounted() {
   
    this.project_id = this.$router.currentRoute.query.project_id
    this.team_code = this.$router.currentRoute.query.team_code
    this.account_id = this.$router.currentRoute.query.account_id
    this.getChecklist()

  },
  methods: {
    getChecklist(){
        netService({
          functionName: 'getTemplateChecks',
         
          project_id: this.project_id,
          account_id :this.account_id ,
          team_code: this.team_code
        
          }).then(data=>{
            
            console.log(data)
            this.mutichecklist = data
          })
      },
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.check-box{
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #409EFF;
  padding: 10px;
}
.check-box-item{
  /* word-wrap:break-word; 
word-break:break-all;  */
word-break:normal;
white-space: pre-wrap;
margin-bottom: 10px;
}
  .container{
    padding: 5%;
    width:90%;
    margin:0 auto 0 auto;
  }
  h2 {
    page-break-before: always;
  }
  .pdf{
    position: fixed;
    top: 3%;

    right: 1%;
    background-color:#1863bb;
    color:white;

  }

  .head1{

    padding-bottom: 10px;
    border-bottom:2px solid black;
    margin-bottom:50px;
  }

 .head2{

    padding-left: 10px;
    border-left:2px solid black;
    
  }

  .name_item{

    margin:15px 5px;
  }

  p {
    line-height: 150%;
    word-break:normal;
    white-space:pre-warp;
    word-wrap:break-word;
  }
  .block{
    margin:50px 10px;
    width: 100%;
  }
  .content {
    white-space: pre-line;
  }
  .file {
    margin: 5px;
  }
  .file a:link {
    color: #aaa;
  }
  .file a:visited {
    color: cornflowerblue;
  }
  @media print {
    .other-ele {
      display: none;
    }
    h1 {font-size: 50px}
    h2 {font-size: 40px}
    h3 {font-size: 30px}
    p {font-size: 16px;}
  }
</style>
