<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-04-19 11:44:00
 * @LastEditTime: 2019-08-23 07:54:54
 * @LastEditors: Please set LastEditors
 -->
<template>
  <el-tabs v-model="activeName2" type="card" @tab-click="handleClick">
    <el-tab-pane v-for="(item, index) in items" :name="index" :key="item.name" :label="item.sheet_info.evaluation_name">
        <div class="tip">提示：{{ last_update_time ? '最近评分时间为' + last_update_time : '你还没有进行评分'}}</div>
        <div class="mark" v-if="!summaryShow">标准名</div>
        <div class="mark" v-if="!summaryShow">打分</div>
           <div v-for="(value, key, index) in item.sheet_info.evaluation_criteria" v-if="!summaryShow">
               <div class="rating">
                  <span>{{key}}</span>
                  <div class="selectbox">
                    <el-select v-model="sheet_scores[key]" placeholder="请选择">
                        <el-option
                        v-for="item in a[index]"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                  </div>
                </div>
           </div>
           <div class="elaboration" v-if="!summaryShow">
             <div class="rating" v-for="(value, key) in evaluation_elaboration" :key="key">
               <p v-html="value"></p>
             </div>
           </div>
        <div v-if="summaryShow">
        <el-table
          :data="tableData"
          style="width: 100%"
          :summary-method="getAverage"
          show-summary>
          <el-table-column
            prop="account_name"
            label="学校" width="300px"
          >
        </el-table-column>  
          <el-table-column
            prop="team_code"
            label="组号" width="50px"
          >
          </el-table-column>
          <el-table-column
            prop="educator_name"
            label="评委姓名" width="200px"
          >
          </el-table-column>
          <!-- <el-table-column
            prop="scores"
            label="评分"
            width="400px"
          >
          <template slot-scope="scope">
            <div v-for="(value, key) in scope.row.scores">
              <span style="margin-left: 10px">{{ key }} : {{value}}</span>
            </div>
          </template>
          </el-table-column> -->
          <template v-for="(value, key) in evaluation_elaboration">
           <el-table-column :label="key" :prop="key" width="130px" :key="key">
            <template slot-scope="scope">
              <span style="margin-left: 10px"> {{scope.row.scores[key]}}</span>
            </template>
           </el-table-column>
          </template>
          <el-table-column
            prop="last_update_time"
            label="评分时间" width="160px">
          </el-table-column>
          <el-table-column
            prop="average"
            label="总分" width="100px">
          </el-table-column>
        </el-table>
        </div>
        <el-button type="primary" class="btn" @click="update" v-if="permission">更新评分</el-button>
        <el-button type="primary" class="sum_btn" @click="summary" v-if="!summaryShow && mode === 'single'">分数汇总</el-button> 
        <el-button type="primary" class="sum_btn" @click="back" v-if="summaryShow && mode === 'single'">返回评分页</el-button>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import Cookie from 'js-cookie'
import {  
    netService,
} from '../service.js'
import { formatDate } from '../util.js'
export default {
  name: 'Score',
  props: {
    
  },
  computed:{
    name(){
      return Cookie.get('user_name')
    }
  },
  data() {
    return{
      allContent: [],
      activeName2: 0,
      show: false,
      items: {},
      rating: {},
      index: 0,
      a: [],
      last_update_time: '',
      tableData: [],
      summaryShow: false,
      permission: true
    }
  },
  mounted() {
   
    this.project_id = this.$router.currentRoute.query.project_id
    this.team_code = this.$router.currentRoute.query.team_code
    this.account_id = this.$router.currentRoute.query.account_id
    this.educator_id = this.$router.currentRoute.query.educator_id
    this.identity = this.$router.currentRoute.query.identity
    this.mode = this.$router.currentRoute.query.mode
    if (this.identity === 'admin') {
      this.permission = false
    }
    if (this.mode === 'single') {
      this.getRating() 
    }else if (this.mode === 'summary'){
      this.getRatingInSummaryPage()
    };
    
  },
  methods: {
    getRating(){
        netService({
          functionName: 'getRatingSheetByProject',
         
          project_id: this.project_id,
          team_code: this.team_code,
          account_id: this.account_id,
          educator_id: this.educator_id
        
          }).then(data=>{
            this.items = data
            this.rating = data[this.index].sheet_info.evaluation_criteria
            this.sheet_id = data[this.index].sheet_info._id.$id
            this.sheet_scores = data[this.index].sheet_scores.length !== 0 ? data[this.index].sheet_scores : data[this.index].sheet_info.evaluation_criteria
            this.a = []
            this.last_update_time = data[this.index].last_update_time ? formatDate(new Date(data[this.index].last_update_time * 1000),  'yyyy-MM-dd') : ''
            this.evaluation_elaboration = data[this.index].sheet_info.evaluation_elaboration
            for (let key in this.evaluation_elaboration) {
                this.evaluation_elaboration[key] = this.evaluation_elaboration[key].replace(/\n/g,'<br/>')
            }
            for (let key in this.rating) {
                let len = Number(this.rating[key])
                let res = []
                for (let i = 0; i <= len; i++){
                    res.push({
                        value: String(i),
                        label: String(i)
                    })
                }
                this.a.push(res)
            }
          })
      },

      getRatingInSummaryPage(){
        netService({
          functionName: 'getRatingSheetByProjectInSummaryPage',
         
          project_id: this.project_id,
          
        
          }).then(data=>{
            this.items = data
            this.rating = data[this.index].sheet_info.evaluation_criteria
            this.sheet_id = data[this.index].sheet_info._id.$id
            this.sheet_scores = data[this.index].sheet_scores.length !== 0 ? data[this.index].sheet_scores : data[this.index].sheet_info.evaluation_criteria
            this.a = []
            this.last_update_time = data[this.index].last_update_time ? formatDate(new Date(data[this.index].last_update_time * 1000),  'yyyy-MM-dd') : ''
            this.evaluation_elaboration = data[this.index].sheet_info.evaluation_elaboration
            for (let key in this.evaluation_elaboration) {
                this.evaluation_elaboration[key] = this.evaluation_elaboration[key].replace(/\n/g,'<br/>')
            }
            for (let key in this.rating) {
                let len = Number(this.rating[key])
                let res = []
                for (let i = 0; i <= len; i++){
                    res.push({
                        value: String(i),
                        label: String(i)
                    })
                }
                this.a.push(res)
            }


            this.totalSummary()
          })
      },
    p(e){
      e.path[1].style.display = 'none'
      window.print()
      e.path[1].style.display = 'block'
    },
    handleClick(tab){
        this.index = tab.name
        // this.getRating()

        if (this.mode === 'single') {
          this.getRating() 
        }else if (this.mode === 'summary'){
          this.getRatingInSummaryPage()
        };
    },
    update(){
        let scores = {}
        for(let key in this.sheet_scores){
            scores[key] = Number(this.sheet_scores[key])
        }
        netService({
          functionName: 'updateEvaluation',
         
          project_id: this.project_id,
          team_code: this.team_code,
          account_id: this.account_id,
          educator_id: this.educator_id,
          sheet_id: this.sheet_id,
          scores: scores
        
          }).then(data=>{
            this.getRating()
            this.$message({
                message: '更新评分成功',
                type: 'success'
            })
          })
    },
    back() {
        this.summaryShow = false
    },
    totalSummary(){
      this.summaryShow = true
      netService({
          functionName: 'getTotalRatingSheetsByProject',
         
          project_id: this.project_id,
          sheet_id: this.sheet_id,
        
          }).then(data=>{
            this.tableData = data
            this.tableData.map( (item) => {
                return item.last_update_time = formatDate(new Date(item.last_update_time * 1000),  'yyyy-MM-dd')
            })
            // console.log(this.evaluation_elaboration)
            this.$message({
                message: '获取汇总成功',
                type: 'success'
            })
        })
    },
    summary() {
        this.summaryShow = true
        netService({
          functionName: 'getRatingSheetsByProject',
         
          project_id: this.project_id,
          team_code: this.team_code,
          account_id: this.account_id,
          sheet_id: this.sheet_id,
        
          }).then(data=>{
            this.tableData = data
            this.tableData.map( (item) => {
                return item.last_update_time = formatDate(new Date(item.last_update_time * 1000),  'yyyy-MM-dd')
            })
            let res = []
            let scores = []
            let _sum = {}
            for(let i = 0; i < data.length; i++) {
                let sum = 0
                for(let key in data[i].scores){
                    sum += Number(data[i].scores[key])
                    res.push(key)
                }
                this.tableData[i].average =  sum
            }
            this.tableData.map( (item) => {
                item['scores']['average'] = item.average
                scores.push(item['scores'])
            })
            for (let key in scores[0]){
                _sum[key] = (scores.map(row => Number(row[key])).reduce((acc, cur) => (cur + acc), 0) / scores.length).toFixed(2)
            }
            this._average = _sum
            console.log(this._average)
            // console.log(this.evaluation_elaboration)
            this.$message({
                message: '获取汇总成功',
                type: 'success'
            })
        })
    },
    getAverage(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '平均分'
            return
          }
          if (this.evaluation_elaboration[column.property]) {
            // console.log(this._average)
            sums[index] = this._average[column.property]
          }
          if (column.property == 'average') {
            sums[index] = this._average[column.property]
          }
      })
      return sums
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .rating{
        width: 10000px;
        min-height: 100px;
        position: relative;
        margin: 15px;
    }
    .rating p{
        color: #aaa;
        font-size: 10px;
    }
    .rating span {
        display: block;
        width: 180px;
    }
    .mark{
        display: inline-block;
        margin: 20px 150px 20px 20px;
    }
    .selectbox{
        position: absolute;
        width: 100%;
        left: 200px;
        top: 0;
    }
    .btn{
        margin: 30px 0 0 80px;
        width: 150px;
    }
    .sum_btn{
        width: 150px;
    }
    .elaboration{
        position: absolute;
        top: 120px;
        left: 500px;
    }
    .tip {
        margin: 20px;
        color: #aaa;
    }
</style>
<style>
  .el-table .cell {
        word-break: keep-all;
    }
</style>
