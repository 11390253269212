<template>
  <div class="ClassList">
      <Head />
      <el-table
      :data="tableData2"
      style="width: 100%"
      
       
      >
      <el-table-column
        prop="number"
        label="编号" width="60px"
        >
      </el-table-column>
      <el-table-column
        prop="block_name"
        label="模块"
        width="250px"
        >
      </el-table-column>
      <el-table-column
        
        label="角色" width="130px">
        <template slot-scope="scope">
            <span >{{scope.row.educator_project_role? scope.row.educator_project_role:'无'}}</span>
        </template>
      </el-table-column>
     <!--  <el-table-column
        prop="educator_name"
        label="导师" width="100px">
      </el-table-column> -->
      <el-table-column
        
        label="点评">
        <template slot-scope="scope">
            <span @click="editComent(scope.row.comment_text, scope.row)">{{ scope.row.comment_text  }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间" width="120px">
      </el-table-column>
    </el-table>


    <el-dialog
      title="修改评语"
      :visible.sync="dialogVisible"
     
      >
      <el-input
        type="textarea"
        :rows="2"
        
        v-model="textarea">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEdit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { netService } from '../service.js'
import Head from './Head'
import Cookie from 'js-cookie'
import { formatDate } from '../util.js'

export default {
  name: 'Comment',
  components: {
    Head,
  },
  data() {
    return {
      tableData2: [],
      class_id: '',
      dialogVisible: false,
      textarea: '',
      nowIndex: 0
    }
  },
  mounted() {
    const studentid = Cookie.get('studentid')
    this.project_id = this.$router.currentRoute.query.project_id
    this.account_id = this.$router.currentRoute.query.account_id
    this.team_code = this.$router.currentRoute.query.team_code
    this.getList()
  },
  methods: {
    getList() {
      netService({
        functionName: 'getComments',
        project_id: this.project_id,
        account_id: this.account_id,
        team_code: this.team_code
      }).then(data => {
        console.log(data)
        data.map(i => {
          this.tableData2.push({
            block_name: i.block_name,
            educator_name: i.educator_name,
            comment_text: i.comment_text,
            create_time: formatDate(new Date(i.create_time * 1000), 'yyyy-MM-dd'),
            educator_project_role: i.educator_project_role
          })
        })
        for(let i = 0; i < this.tableData2.length; i++){
          this.tableData2[i].number = i + 1
          this.tableData2[i].id = data[i]._id.$id
        }
      })
    },
    editComent(content,obj){
      this.dialogVisible = true
      this.textarea = content
      console.log(obj)
      this.nowIndex = obj.number - 1
    },

    confirmEdit(){
      this.dialogVisible = false
      this.tableData2[this.nowIndex].comment_text = this.textarea
      netService({
          functionName: 'updateCommentText',
          comment_id: this.tableData2[this.nowIndex].id,
          text: this.textarea,
         
        }).then(data => {
          if(data == null){
            
            this.$message({
                message: '修改成功~',
                type: 'success',
                duration: 1500,
                
              });
          }
        })

    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
.el-table{
      width: 80%!important;
    margin: 0 auto;
}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  .ClassList{
    width: 100%;
    /* margin: 0 auto; */
  }
</style>
<style>
.el-table .cell{
  word-break: keep-all;
}
</style>

