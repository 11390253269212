import axios from 'axios'

export function login({
  student_pwd,
  student_email
}) {
  return axios.post('./index.php', {
      function: 'loginByStudent',
      student_pwd,
      student_email,
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function loginByContact({
  contact_email,
  contact_pwd
}) {
  return axios.post('./index.php', {
      function: 'loginByContact',
      contact_pwd,
      contact_email,
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function getStudentClasses({
  student_id
}) {
  return axios.post('./index.php', {
      function: 'getStudentClasses',
      student_id
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}


export function getSessions({
  class_id
}) {
  return axios.post('./index.php', {
      function: 'getSessions',
      class_id
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function getContents({
  session_id,
  student_id
}) {
  return axios.post('./index.php', {
      function: 'getContents',
      session_id,
      student_id
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function getContent({
  content_id
}) {
  return axios.post('./index.php', {
      function: 'getContent',
      content_id
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}
export function uploadQuiz({
  quiz_id,
  student_id,
  quiz_answer,
}) {
  return axios.post('./index.php', {
      function: 'uploadQuiz',
      quiz_id,
      student_id,
      quiz_answer,
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function getStudentProjects({
  student_id
}) {
  return axios.post('./index.php', {
      function: 'getStudentProjects',

      student_id,

    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}


export function getTemplate({
  template_id
}) {
  return axios.post('./index.php', {
      function: 'getTemplate',
      template_id
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function getStudentBlock({
  team_code,
  project_id,
  student_id,
  block_id
}) {
  return axios.post('./index.php', {
      function: 'getStudentBlock',
      team_code,
      project_id,
      student_id,
      block_id
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}


export function updateStudentBlock(param) {
  return axios.post('./index.php', {
      function: 'updateStudentBlock',
      ...param
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function getProjectTeamcodes({
  project_id
}) {
  return axios.post('./index.php', {
      function: 'getProjectTeamcodes',
      project_id
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function loginByEducator({
  educator_pwd,
  educator_email,
}) {
  return axios.post('./index.php', {
      function: 'loginByEducator',
      educator_pwd,
      educator_email,
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function uploadStaticBlockAttach({
  block_id,
  project_id,
  account_id,
  team_code,
  attach_name,
  attach_type
}) {
  return axios.post('./index.php', {
      function: 'uploadStaticBlockAttach',
      block_id,
      project_id,
      account_id,
      team_code,
      attach_name,
      attach_type
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function netService({
  functionName,
  ...p
}){
  return axios.post('./index.php', {
      function: functionName,
      ...p
    })
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error);
    })
}

export function upload({
  param,
  config
}){
  console.log(param)
  return axios.post('./index.php', 
    param
  , config)
    .then(response => {
      console.log(response.data);
      return response.data
    })
}

export function getCertifiTemplatesByProject({project_id}){
  return axios.post('./index.php',{
    function:'getCertifiTemplatesByProject',
    project_id
  }).then(function(response){
    return response.data
  }).catch(function(error){
    console.log(error)
  })
}

export function export_csv({data,title,file_name}){
  return axios.post('./index.php',{
    function:'export_csv',
    data,
    title,
    file_name
  }).then(function(response){
    return response
  }).catch(function(error){
    console.log(error)
  })
}


export function sendEmail(student_id,project_id){
    return axios.post('./index.php',{
        function:'sendEmail',
        student_id,
        project_id
    })
}
export function updateStudentBlockCheck(param){
    return axios.post('./index.php',{
        function:'updateStudentBlockCheck',
        ...param
    })

}


export function sendNewPwd(email,type){
    return axios.post('./index.php',{
      function:'sendNewPwd',
      email,type
    })
  }
  export function judgeForbidStatus(project_id,account_id,student_id){
      return axios.post('./index.php',{
        function:'judgeForbidStatus',
        project_id,account_id,student_id
      })
    }

export function getQiniuToken(){
  return axios.post('./index.php',{
    function:'getQiniuToken'
  })
}
    