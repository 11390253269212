<template>
  <div class="container">
    <div class="content">
      <div v-for="item in contents">
        <div :style="{'background-color':getBgColor()}" class="item-container">
          <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;width: 100%">
            <div class="circle" :style="{'background-color': getCircleColor()}"/>
            <p class="title" :style="{'color':getTitleColor()}">{{ item.template_block }}</p>
          </div>
          <div v-for="child in item.block_content" style="margin-top: 10px">
            <p :style="{'color':getContentColor()}" class="block-content item-content-1">{{ child }}</p>
          </div>
          <div v-for=" (attach,attach_index) in item.block_attaches" style="margin-top: 20px;">
            <AttachView :project_type="project_type" :attach_index="attach_index" :attach_info="attach" :second-block="1"></AttachView>
          </div>
          <p style="margin-top: 20px;color:gray"
             v-if="item.block_content.length === 0 && item.block_attaches.length === 0 ">
            <i>This part is absent or not available temporarily.</i>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {blockColorMap, contentColorMap, blockContentLevel2BgMap} from '../../GlobalConfig'
import AttachView from "./AttachView";

export default {
  name: "PrintBlockLevel2",
  components: {
    AttachView
  },
  props: {
    project_type: {
      type: String,
      default: () => {
      }
    },
    contents: {
      type: Array,
      default: () => {
      }
    },
  },
  methods: {
    getCircleColor() {
      return blockColorMap[this.project_type]
    },
    getTitleColor() {
      return blockColorMap[this.project_type]
    },
    getContentColor() {
      return contentColorMap[this.project_type]
    },
    getBgColor() {
      return blockContentLevel2BgMap[this.project_type]
    }
  }
}
</script>

<style scoped lang="less">

.item-content-1 {
  margin: 20px 0 0 0;
  width: 100%
}

.item-container {
  padding: 40px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.circle {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px
}

.title {
  font-size: 22px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold
}

.container {
}

.content {
}
</style>