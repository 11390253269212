<template>
  <div class="print-block-container">
    <div class="print-block-content">
      <div style="display: flex;flex-direction: row;align-items: center;">
        <el-image :src="getIconPath()" lazy
                  style="width: 36px;height: auto;margin-right: 12px"/>
        <p :style="{'color':getBlockTitleColor()}" class="block-title">{{ template_block }}</p>
      </div>
      <div style="margin-top: 20px;width: 100%">
        <div v-if="viewType ==0">
          <div v-for="item in contents">
            <div v-for="child in item.block_content">
              <p class="block-content item-content-1" :style="{'color':getContentColor()}">{{
                  child
                }}</p>
            </div>
            <div v-for=" (attach,attach_index) in item.block_attaches" style="margin-top: 20px">
              <AttachView :project_type="project_type" :attach_index="attach_index" :attach_info="attach" :second-block="0"></AttachView>
            </div>
          </div>
        </div>
        <div v-if="viewType ==1">
          <PrintBlockLevel2 :project_type="project_type" :contents="getSubContents()"/>
        </div>
      </div>
      <div style="margin-top: 20px" v-if="judgeComments && judgeComments.length>0">
        <div v-for="item in judgeComments">
          <p class="block-content item-content-1" :style="{'color':getContentColor()}">{{
              item.comment_text
            }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrintBlockLevel2 from "./PrintBlockLevel2";
import AttachView from "./AttachView";
import {blockColorMap, contentColorMap, blockContentLevel2BgMap} from '../../GlobalConfig'

export default {
  name: "PrintBlockNormal",
  components: {
    PrintBlockLevel2,
    AttachView
  },
  props: {
    project_type: {
      type: String,
      default: () => {
      }
    },
    template_block: {
      type: String,
      default: () => {
      }
    },
    contents: {
      type: Array,
      default: () => {
      }
    },
    judgeComments: {
      type: Array,
      default: () => {
      }
    },
    dataType: {
      type: Number,
      default: () => {
      }
    },
    viewType: {
      type: Number,
      default: () => {
      }
    },
  },
  methods: {
    getSubContents() {
      let array = []
      if (this.contents.length > 0) {
        for (var i = 0; i < this.contents.length; i++) {
          array.push(this.contents[i])
        }
      }
      return array
    },
    getBlockTitleColor() {
      return blockColorMap[this.project_type]
    },
    getContentColor() {
      return contentColorMap[this.project_type]
    },
    getIconPath() {
      if (this.dataType == 1) {
        return require('../../assets/' + this.project_type + '/' + this.project_type + '_judge_comments.png')
      } else {
        if (this.template_block == 'Disclaimer') {
          return require('../../assets/' + this.project_type + '/' + this.project_type + '_disclaimer.png')
        } else if (this.template_block == 'Grant of License') {
          return require('../../assets/' + this.project_type + '/' + this.project_type + '_grant_of_license.png')
        } else if (this.template_block == 'Youth Observation Training Course') {
          return require('../../assets/' + this.project_type + '/' + this.project_type + '_yotc.png')
        } else if (this.template_block == 'Summary') {
          return require('../../assets/' + this.project_type + '/' + this.project_type + '_summary.png')
        } else if (this.template_block == 'Research Plan') {
          return require('../../assets/' + this.project_type + '/' + this.project_type + '_Research_plan.png')
        } else if (this.template_block == 'Main Report') {
          return require('../../assets/' + this.project_type + '/' + this.project_type + '_main_report.png')
        } else if (this.template_block == 'Review and Reflections') {
          return require('../../assets/' + this.project_type + '/' + this.project_type + '_Review_Reflections.png')
        } else if (this.template_block == 'Identify the Topic') {
          return require('../../assets/' + this.project_type + '/' + this.project_type + '_Identify_Topic.png')
        } else {
          return require('../../assets/' + this.project_type + '/' + this.project_type + '_default.png')
        }
      }
    },
  }
}
</script>

<style scoped lang="less">

.item-content-1 {
  margin: 20px 0 0 0;
  width: 100%
}

.print-block-container {
  margin-left: 30px;
  margin-right: 30px;
}

.print-block-content {
  padding: 30px;
  background-color: white;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.0500);
}
</style>