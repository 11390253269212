<template>
  <div style="display: inline-block">
    <div class="attach-container" :style="{'background-color':getBgColor()}">
      <p class="attach-name" :style="{'color':getContentColor()}"> {{
          attach_info.attach_name == '' ? '附件' + (attach_index + 1) : attach_info.attach_name
        }} </p>
      <p class="btnCheck" :style="{'border-color':getBorderColor(),'color':getBlockColor()}" @click="gotoTarget">查看</p>
    </div>
  </div>
</template>

<script>
import {blockColorMap, blockContentLevel2BgMap, contentColorMap} from "../../GlobalConfig";

export default {
  name: "AttachView",
  props: {
    project_type: {
      type: String,
      default: () => {
      }
    },
    attach_index: {
      type: Number,
      default: () => {
      }
    },
    secondBlock: {
      type: Number,
      default: () => {

      }
    },
    attach_info: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {

    gotoTarget() {
      window.open(this.attach_info.attach_address, '_blank')
    },
    getContentColor() {
      return contentColorMap[this.project_type]
    },
    getBgColor() {
      if (this.secondBlock == 0) {
        return blockContentLevel2BgMap[this.project_type]
      } else {
        return 'rgba(255,255,255,0.8)'
      }
    },
    getBlockColor() {
      return blockColorMap[this.project_type]
    },
    getBorderColor() {
      return blockColorMap[this.project_type]
    }
  }
}
</script>

<style scoped lang="less">
.attach-container {
  display: flex;
  flex-direction: row;
  padding: 18px;
  max-width: 740px;
  box-sizing: border-box;
  border-radius: 5px;
}

.btnCheck {
  cursor: pointer;
  margin-left: 30px;
  font-size: 16px;
  text-align: center;
  line-height: 32px;
  width: 88px;
  height: 32px;
  border-radius: 5px 5px 5px 5px;
  border-width: 1px;
  border-style: solid;
  font-family: AlibabaPuHuiTi-2-65-Medium
}

.attach-name {
  line-height: 36px;
  font-size: 18px;
  font-family: AlibabaPuHuiTi-2-65-Medium
}

</style>